import React from 'react';
import DefaultLayout from '../layouts/default';
import FluidImage from '../components/FluidImage.js';
import Breadcrumbs from '../components/Custom/Navigation/Breadcrumbs';
import { Link, graphql, StaticQuery } from 'gatsby';
import parse from 'html-react-parser';

const References = ({ location, data }) => {
//  const dispatch = useDispatch();

//   React.useEffect(() => {

//   });

    const fixContent = (content) => {
        return parse(content, {
            replace: domNode => {
            if (domNode.name === 'img')
                return (
                <>
                    <FluidImage
                        src={domNode.attribs.src}
                        className="img"
                    />
                </>
                );
            },
        });
    };


    const getPro = (project, key) => {

        switch(project.level){
            case 1:
                //console.log("getPro xxx", project);
                return <div key={key} className="column is-half-tablet is-one-third-desktop is-one-quarter-fullhd">
                    <div className="card card-feature">
                        <Link to={project.fields.slug} />
                        <div className="card-body">
                            <div className="card-image">
                                <FluidImage
                                    src={"/static/images/" + project.image}
                                    className="image"
                                    alt="Name of Project"
                                    />
                            </div>
                            <div className="card-content">
                                <p className="title">{project.caption}</p>
                                <p className="subtitle">{project.subtitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
                break;

            case 2:
                return <tr key={key}>
                    <td className="title">{project.caption}</td>
                    <td>{project.subtitle}</td>
                    <td>{project.client || ""}</td>
                    <td>{project.website && <a href={project.website}>{project.website.replace("https://", "").replace("http://", "")}</a>}</td>
                </tr>
                break;

            case 3:
                return <div key={key} className="column is-half-mobile is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen">
                    <div className="card card-feature">
                        <Link to={project.fields.slug} />
                        <div className="card-body">
                            <div className="card-image">
                                <FluidImage
                                    src={"/static/images/" + project.image}
                                    className="image"
                                    alt={project.caption}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
        }
        
    }

    let pros = {level1: [], level2: [], level3: []};
    let key = 0;
    data.projects.nodes.forEach(project => {
        pros["level" + project.level].push(getPro(project, key) );
        key++;
    });

    return (
        <DefaultLayout location={location}>

            <Breadcrumbs location={location} />
            
            {/* on this page */}
            <div className="container is-fullwidth">
                <section className="section section-mini">
					<p className="is-normal">
						On this page:{" "}
						<a href="#1" className="">
							Projects
						</a>
						,{" "}
						<a href="#2" className="">
							Other projects
						</a>
						{/* ,{" "}
						<a href="#3" className="">
							Clients
						</a> */}
					</p>
				</section>
            </div>

            {/* project features */}
            <a id="1" className="scroll-anchor"></a>
            <div className="container is-fullwidth">
                <section className="section">
                    <div className="columns is-multiline 0is-gapless 0is-mobile columns-feature">
                        {pros.level1.slice(0,4)}
                    </div>
                </section>
            </div>

            {/* other projects */}
            <a id="2" className="scroll-anchor"></a>
            <div className="container is-layout">
                <section className="section">
                    <div className="columns is-tablet is-centered">
                        <div className="column is-8">
                            <h1>We run other projects, too.</h1>
                        </div>
                    </div>
                    
                    <div className="table-container">
                        <table className="table is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Project</th>
                                    <th>Topic</th>
                                    <th>Client</th>
                                    <th>Website</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pros.level2}
                            </tbody>
                        </table>
                    </div>

                    <p className="is-normal">... and a few more.</p>

                </section>
            </div>

            {/* member's references */}
            {/* <a id="3" className="scroll-anchor"></a>
            <div className="container is-layout">
                <section className="section">

                    <div className="columns is-tablet is-centered">
                        <div className="column is-8">
                            <h1>Plus, our members are involved in fruitful business relations.</h1>
                            <p>&mdash; Our members come from a diverse background, many of them being independent entrepreneurs. Together we bring in all the expertise needed to create prosperous businesses.</p>
                        </div>
                    </div>
                    <div className="columns is-tablet is-centered">
                        <div className="column is-8">
                            <p className="is-normal">You might say that name dropping of well-known companies sucks. We'll drop names anyway.</p>
                        </div>
                    </div>
                    <div className="columns is-tablet is-centered">
                        <div className="column is-8">
                            <p>These are just some of the fine organisations we work with:</p>
                        </div>
                    </div>

                </section>
            </div>

            <div className="container is-fullwidth">
                <section className="section">
                    <div className="columns is-multiline is-gapless is-mobile columns-feature">

                        {pros.level3}

                    </div>
                </section>
            </div>
                 */}
        </DefaultLayout>
    );
};


export const query = graphql`
    query ReferencesQuery {
        projects: allReferencesYaml(sort: {fields: sorting}) {
            nodes {
                caption
                website
                client
                subtitle
                image
                sorting
                level
                fields {
                    slug
                }
            }
        }
    }
`

export default References;

